import React, { FC, useCallback, useRef } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useListQuiz from "../../hooks/queries/useListQuiz";
import Spinner from "../../components/spinner";
import { Subject } from "../../models/subject";
import LoadingError from "../../components/loadingError";
import { QuizType } from "../../models/quizType";
import useSaveQuizResult from "../../hooks/queries/useSaveQuizResult";
import StudentAnswers from "../../models/studentAnswers";
import QuizResultSaveResult from "../../models/quizResultSaveResult";
import AlertDialogWithRetry from "../../components/alertDialogWithRetry";
import QuizRoot from "./components/quizRoot";
import styles from "./_index.module.scss";
import useQuizUiState from "./hooks/useQuizUiState";
import {
  mapNavQuizTypeToModel,
  mapQuizTypeToNavModel,
  NavQuizType,
} from "../../navigation/models/navQuizType";

interface ContentProps {
  subject: Subject;
  categoryId: string;
  categoryName: string;
  quizzes: QuizType[];
}

const Content: FC<ContentProps> = ({
  subject,
  categoryId,
  categoryName,
  quizzes,
}) => {
  const navigate = useNavigate();

  const {
    mutate,
    isLoading: isSaving,
    isError: isSaveError,
    variables,
  } = useSaveQuizResult();

  const saving = useRef<boolean>(isSaving);
  saving.current = isSaving;

  const saveAnswers = useCallback(
    (answers: StudentAnswers, incorrectQuizzes: QuizType[]) => {
      if (saving.current) {
        return;
      }

      mutate(
        {
          subject,
          categoryId,
          answers: answers.answers,
        },
        {
          onSuccess: ({ categoryCompleted }: QuizResultSaveResult) => {
            const result = categoryCompleted
              ? { incorrectQuizzes, categoryName }
              : {
                  incorrectQuizzes,
                  categoryName,
                  correctAnswerCount: answers.correctAnswerCount,
                  totalQuizCount: answers.totalQuizCount,
                };

            navigate("finished", {
              replace: true,
              state: {
                ...result,
                incorrectQuizzes: result.incorrectQuizzes.map(
                  mapQuizTypeToNavModel
                ),
              },
            });
          },
        }
      );
    },
    []
  );

  const useQuizState = useQuizUiState(quizzes, saveAnswers);

  return (
    <div className={styles.content}>
      <QuizRoot
        useQuizState={useQuizState}
        onHomeClick={() => navigate("/", { replace: true })}
      />

      {isSaving && <Spinner />}

      {isSaveError && (
        <AlertDialogWithRetry
          onRetry={() =>
            variables &&
            saveAnswers(
              new StudentAnswers(variables.answers),
              useQuizState.incorrectQuizzes
            )
          }
        />
      )}
    </div>
  );
};

interface QuizLoaderProps {
  subject: Subject;
  categoryId: string;
  categoryName: string;
}

const QuizLoader: FC<QuizLoaderProps> = ({
  subject,
  categoryId,
  categoryName,
}) => {
  const { isLoading, isError, data, refetch } = useListQuiz(categoryId);

  if (isError) {
    return <LoadingError onRetry={refetch} />;
  }

  if (isLoading || data === undefined) {
    return <Spinner />;
  }

  return (
    <Content
      quizzes={data}
      subject={subject}
      categoryId={categoryId}
      categoryName={categoryName}
    />
  );
};

export interface QuizState {
  categoryName?: string;
  incorrectQuizzes?: NavQuizType[];
}

export interface QuizProps {
  subject: Subject;
}

const Quiz: FC<QuizProps> = ({ subject }) => {
  const location = useLocation();
  const state = location.state as QuizState;
  const categoryName = state?.categoryName;

  const { categoryId } = useParams();

  if (categoryName === undefined || categoryId === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.Quiz}>
      {state.incorrectQuizzes ? (
        <Content
          subject={subject}
          categoryId={categoryId}
          categoryName={categoryName}
          quizzes={state.incorrectQuizzes?.map(mapNavQuizTypeToModel)}
        />
      ) : (
        <QuizLoader
          subject={subject}
          categoryId={categoryId}
          categoryName={categoryName}
        />
      )}
    </div>
  );
};

export default Quiz;
