import React, { FC, useCallback, useRef } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Spinner from "../../components/spinner";
import LoadingError from "../../components/loadingError";
import { QuizType } from "../../models/quizType";
import StudentAnswers from "../../models/studentAnswers";
import QuizResultSaveResult from "../../models/quizResultSaveResult";
import AlertDialogWithRetry from "../../components/alertDialogWithRetry";
import QuizRoot from "../quiz/components/quizRoot";
import styles from "./_index.module.scss";
import useQuizUiState from "../quiz/hooks/useQuizUiState";
import {
  mapNavQuizTypeToModel,
  mapQuizTypeToNavModel,
  NavQuizType,
} from "../../navigation/models/navQuizType";
import { MonthlyExamPreparationSubject } from "../../models/monthlyExamPreparationSubject";
import useListMonthlyExamQuizzes from "../../hooks/queries/useListMonthlyExamQuizzes";
import useSaveMonthlyExamQuizResult from "../../hooks/queries/useSaveMonthlyExamQuizResult";

interface ContentProps {
  subject: MonthlyExamPreparationSubject;
  preparationNumber: number;
  quizzes: QuizType[];
}

const Content: FC<ContentProps> = ({ subject, preparationNumber, quizzes }) => {
  const navigate = useNavigate();

  const {
    mutate,
    isLoading: isSaving,
    isError: isSaveError,
    variables,
  } = useSaveMonthlyExamQuizResult();

  const saving = useRef<boolean>(isSaving);
  saving.current = isSaving;

  const saveAnswers = useCallback(
    (answers: StudentAnswers, incorrectQuizzes: QuizType[]) => {
      if (saving.current) {
        return;
      }

      mutate(
        {
          preparationNumber,
          subject,
          answers: answers.answers,
        },
        {
          onSuccess: ({ categoryCompleted }: QuizResultSaveResult) => {
            const result = categoryCompleted
              ? { incorrectQuizzes, preparationNumber }
              : {
                  incorrectQuizzes,
                  preparationNumber,
                  correctAnswerCount: answers.correctAnswerCount,
                  totalQuizCount: answers.totalQuizCount,
                };

            navigate("finished", {
              replace: true,
              state: {
                ...result,
                incorrectQuizzes: result.incorrectQuizzes.map(
                  mapQuizTypeToNavModel
                ),
              },
            });
          },
        }
      );
    },
    []
  );

  const useQuizState = useQuizUiState(quizzes, saveAnswers);

  return (
    <div className={styles.content}>
      <QuizRoot
        useQuizState={useQuizState}
        onHomeClick={() => navigate("/", { replace: true })}
      />

      {isSaving && <Spinner />}

      {isSaveError && (
        <AlertDialogWithRetry
          onRetry={() =>
            variables &&
            saveAnswers(
              new StudentAnswers(variables.answers),
              useQuizState.incorrectQuizzes
            )
          }
        />
      )}
    </div>
  );
};

interface QuizLoaderProps {
  subject: MonthlyExamPreparationSubject;
  preparationNumber: number;
}

const QuizLoader: FC<QuizLoaderProps> = ({ subject, preparationNumber }) => {
  const { isLoading, isError, data, refetch } = useListMonthlyExamQuizzes(
    subject,
    preparationNumber
  );

  if (isError) {
    return <LoadingError onRetry={refetch} />;
  }

  if (isLoading || data === undefined) {
    return <Spinner />;
  }

  return (
    <Content
      quizzes={data}
      subject={subject}
      preparationNumber={preparationNumber}
    />
  );
};

export interface QuizState {
  incorrectQuizzes?: NavQuizType[];
}

export interface QuizProps {
  subject: MonthlyExamPreparationSubject;
}

const MonthlyExamPreparation: FC<QuizProps> = ({ subject }) => {
  const { id } = useParams();

  const location = useLocation();
  const incorrectQuizzes = location.state
    ? (location.state as QuizState).incorrectQuizzes
    : undefined;

  if (id === undefined) {
    return <Navigate to="/" replace />;
  }

  const preparationNumber = Number(id);

  return (
    <div className={styles.MonthlyExamPreparation}>
      {incorrectQuizzes ? (
        <Content
          subject={subject}
          preparationNumber={preparationNumber}
          quizzes={incorrectQuizzes.map(mapNavQuizTypeToModel)}
        />
      ) : (
        <QuizLoader subject={subject} preparationNumber={preparationNumber} />
      )}
    </div>
  );
};

export default MonthlyExamPreparation;
